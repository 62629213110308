import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash-core'
import requests from '../../requests'
import { INTEGRATION_URL, UPLOAD_URL } from '@/assets/variables/endpoints'

export const state = () => ({
  webchatIntegration: [],
  webchatIntegrationDetail: {
    id: '',
    organization_id: '',
    channel_integration_id: '',
    webhook: '',
    title: '',
    logo: '',
    domain: '',
    is_active: false,
    settings: {},
  },
  widgetAppearanceStyle: {
    header: '#0274f5',
    headerText: '#FFFFFF',
    agentMessage: '#0274f5',
    agentText: '#FFFFFF',
    visitorMessage: '#f6f9ff',
    visitorText: '#2d3d54',
  },
  createMode: 'form',
  widgetContent: {
    whenOnline: "We're available. Ask away to start the chat.",
    whenAway: `Need help? Reach out to us right here, and we'll get back to you as soon as we can!`,
    whenOffline: 'Please fill out the form. We’ll be back soon.',
    preChatForm: {
      title: 'Welcome to our web chat.',
      subtitle: 'Please fill out the form below before asking your questions. ',
    },
    widgetText: {
      is_active: false,
      position: 'top',
      greeting: 'Have questions? We’re here to help.',
    },
    widgetLogo: {
      is_active: false,
      file: null,
      urlFile: null,
    },
  },
  widgetHeaderText: 'WHEN-ONLINE', // WHEN-ONLINE / WHEN-AWAY / WHEN-OFFLINE / PRECHATFORM
  // WidgetContent
  whenOfflineFormLists: [],
  preChatFormLists: [],
  widgetDesktopPreviewBodyType: '',
  validateWidgetContent: {
    whenOnline: false,
    whenOffline: false,
    preChatForm: {
      title: false,
      subtitle: false,
    },
    greetingMessage: false,
  },
  widgetContentErrorMsg: '',
  openBox: {
    widgetOpen: false,
    greetingOpen: true,
  },
})

export const getters = {
  getField,
  getWhenOfflineFormLists(state) {
    return state.whenOfflineFormLists
  },
  getPreChatFormLists(state) {
    return state.preChatFormLists
  },
  getValidateWidgetContent(state) {
    return state.validateWidgetContent
  },
}

export const actions = {
  getWebchatIntegration({ commit, dispatch, state }) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'content', status: true },
      { root: true }
    )
    requests.webchat
      .getService(
        `${INTEGRATION_URL}/webchat/widgets`,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          // commit('layouts/SET_META', res.meta, { root: true })
          commit('SET_WEBCHAT_INTEGRATION_LIST', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        }
      )
  },
  updateWebchatStatus({ commit, dispatch, state }, params) {
    requests.webchat
      .putService(
        `${INTEGRATION_URL}/webchat/widget/status/${params.id}`,
        { is_active: params.is_active },
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('UPDATE_WEBCHAT_STATUS', res.data)
          commit(
            'layouts/DISPLAY_TOAST_PIXEL',
            { title: 'Successfully updated!', variant: 'success' },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/DISPLAY_TOAST_PIXEL',
            { title: err.error.messages[0], variant: 'error' },
            { root: true }
          )
        }
      )
  },
  updateWebchat({ commit, dispatch, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    const param = {
      style: {
        header: {
          bg_color: state.widgetAppearanceStyle.header,
          text_color: state.widgetAppearanceStyle.headerText,
        },
        agent_message: {
          bg_color: state.widgetAppearanceStyle.agentMessage,
          text_color: state.widgetAppearanceStyle.agentText,
        },
        visitor_message: {
          bg_color: state.widgetAppearanceStyle.visitorMessage,
          text_color: state.widgetAppearanceStyle.visitorText,
        },
      },
      when_online: {
        title: state.widgetContent.whenOnline,
      },
      when_offline: {
        title: state.widgetContent.whenOffline,
      },
      pre_chat: {
        title: state.widgetContent.preChatForm.title,
        subtitle: state.widgetContent.preChatForm.subtitle,
      },
      widget_text: {
        is_active: state.widgetContent.widgetText.is_active,
        position: state.widgetContent.widgetText.position,
        greeting: state.widgetContent.widgetText.greeting,
      },
    }
    if (
      state.widgetContent.widgetLogo.file ||
      (!state.widgetContent.widgetLogo.file &&
        !state.widgetContent.widgetLogo.urlFile)
    ) {
      param.widget_logo = {
        is_active: state.widgetContent.widgetLogo.is_active,
        logoUrl: state.widgetContent.widgetLogo.urlFile,
      }
    }
    const widgetSettings = JSON.stringify(param)
    const payload = new FormData()
    payload.append('title', params.title)
    payload.append('domain', params.domain)
    if (
      state.widgetContent.widgetLogo.file &&
      state.widgetContent.widgetLogo.urlFile
    )
      payload.append('logo', state.widgetContent.widgetLogo.file)
    payload.append('widget_settings', widgetSettings)
    return new Promise((resolve, reject) => {
      requests.webchat
        .putService(`${INTEGRATION_URL}/webchat/widget/${params.id}`, payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          async (res) => {
            await commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            resolve(res)
          },
          (err) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  addNewWidget({ commit, dispatch, state }, params) {
    // commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    return new Promise((resolve, reject) => {
      requests.webchat
        .postService(`${INTEGRATION_URL}/webchat`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            // await commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            // await commit(
            //   'layouts/UPDATE_NOTIFICATION',
            //   {
            //     display: true,
            //     type: 'success',
            //     message: 'Widget Created!',
            //     item: '',
            //     callback: {
            //       text: 'OK',
            //       method: 'closeNotification'
            //     }
            //   },
            //   { root: true }
            // )
            resolve(res.data)
          },
          (err) => {
            // commit(
            //   'layouts/UPDATE_NOTIFICATION',
            //   {
            //     display: true,
            //     type: 'failed',
            //     message: 'Error!',
            //     item: err.error.messages[0],
            //     callback: {
            //       text: 'OK',
            //       method: 'closeNotification'
            //     }
            //   },
            //   { root: true }
            // )
            // commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  updateFormMode({ commit, state }, params) {
    commit('UPDATE_FORM_MODE', params)
  },
  updateNotificationError({ commit }) {
    commit(
      'layouts/UPDATE_NOTIFICATION',
      {
        display: true,
        type: 'failed',
        message: 'Error!',
        item: `You don't have permission`,
        callback: {
          text: 'OK',
          method: 'closeNotification',
        },
      },
      { root: true }
    )
  },
  getWebchatIntegrationDetail({ commit, dispatch, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.webchat
      .getService(
        `${INTEGRATION_URL}/webchat/widgets`,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        async (res) => {
          await commit('SET_WEBCHAT_INTEGRATION_LIST', res.data)
          await commit('SET_WEBCHAT_INTEGRATION_DETAIL', params)
          await commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  updateWidgetHeaderText({ commit, dispatch, state }, params) {
    commit('UPDATE_WIDGET_HEADER_TEXT', params)
  },
  updateWidgetStyle({ commit, dispatch, state }, params) {
    const payload = {
      header: params.header
        ? params.header
        : state.widgetAppearanceStyle.header,
      headerText: params.headerText
        ? params.headerText
        : state.widgetAppearanceStyle.headerText,
      agentMessage: params.agentMessage
        ? params.agentMessage
        : state.widgetAppearanceStyle.agentMessage,
      agentText: params.agentText
        ? params.agentText
        : state.widgetAppearanceStyle.agentText,
      visitorMessage: params.visitorMessage
        ? params.visitorMessage
        : state.widgetAppearanceStyle.visitorMessage,
      visitorText: params.visitorText
        ? params.visitorText
        : state.widgetAppearanceStyle.visitorText,
    }
    commit('UPDATE_WIDGET_APPEARANCE_STYLE', payload)
  },
  updateWidgetOpen({ commit, dispatch, state }, params) {
    commit('UPDATE_WIDGET_OPEN', params)
    if (state.widgetContent.widgetText.is_active)
      commit('UPDATE_GREETING_OPEN', true)
  },
  async addFile({ commit }, params) {
    const formdata = new FormData()
    formdata.append('file', params.file)
    const additional = {
      headers: {
        Authorization: this.$auth.getToken('hub'),
      },
    }
    const {
      data: { data },
    } = await this.$axios.post(UPLOAD_URL, formdata, additional)
    const payload = {
      files: params.file,
      url: data.url,
    }
    await commit('SET_FILE_LOGO', payload)
  },
  removeWidget({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.webchat
        .deleteService(
          `${INTEGRATION_URL}/webchat/${params}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_WIDGET_APPEARANCE_STYLE(state, payload) {
    state.widgetAppearanceStyle.header = payload.header
    state.widgetAppearanceStyle.headerText = payload.headerText
    state.widgetAppearanceStyle.agentMessage = payload.agentMessage
    state.widgetAppearanceStyle.agentText = payload.agentText
    state.widgetAppearanceStyle.visitorMessage = payload.visitorMessage
    state.widgetAppearanceStyle.visitorText = payload.visitorText
  },
  SET_WEBCHAT_INTEGRATION_LIST(state, payload) {
    state.webchatIntegration = payload
  },
  UPDATE_WEBCHAT_STATUS(state, payload) {
    const index = _.findIndex(state.webchatIntegration, function (o) {
      return o.id === payload.id
    })
    const integration = _.find(state.webchatIntegration, function (o) {
      return o.id === payload.id
    })
    if (integration) {
      integration.is_active = payload.is_active
      state.webchatIntegration.splice(index, 1, integration) // remove 1 array at index and re-add element room
    }
  },
  UPDATE_FORM_MODE(state, payload) {
    state.createMode = payload
  },
  SET_WEBCHAT_INTEGRATION_DETAIL(state, payload) {
    const findIntegration = _.find(state.webchatIntegration, function (o) {
      return o.id === payload
    })
    state.webchatIntegrationDetail = findIntegration

    if (findIntegration.settings.when_online) {
      state.widgetContent.whenOnline =
        findIntegration.settings.when_online.title
    } else {
      state.widgetContent.whenOnline =
        "We're available. Ask away to start the chat." // default
    }

    if (findIntegration.settings.when_offline) {
      state.widgetContent.whenOffline =
        findIntegration.settings.when_offline.title
    } else {
      state.widgetContent.whenOffline =
        "Please fill out the form. We'll be back soon." // default
    }

    if (findIntegration.settings.pre_chat) {
      state.widgetContent.preChatForm = { ...findIntegration.settings.pre_chat }
    } else {
      state.widgetContent.preChatForm.title = 'Welcome to our web chat.'
      state.widgetContent.preChatForm.subtitle =
        'Please fill out the form below before asking your questions. '
    }

    if (findIntegration.settings.widget_text) {
      if (findIntegration.settings.widget_text.greeting)
        state.widgetContent.widgetText = {
          ...findIntegration.settings.widget_text,
        }
      else {
        state.widgetContent.widgetText.is_active = false
        state.widgetContent.widgetText.position = 'top'
        state.widgetContent.widgetText.greeting =
          "Have questions? We're here to help."
      }
    } else {
      state.widgetContent.widgetText.is_active = false
      state.widgetContent.widgetText.position = 'top'
      state.widgetContent.widgetText.greeting =
        "Have questions? We're here to help."
    }

    if (findIntegration.logo && findIntegration.settings.widget_logo) {
      state.widgetContent.widgetLogo.is_active =
        findIntegration.settings.widget_logo.is_active
      state.widgetContent.widgetLogo.urlFile = findIntegration.logo.url
    }
  },
  UPDATE_WIDGET_HEADER_TEXT(state, payload) {
    state.widgetHeaderText = payload
  },
  ADD_WHEN_OFFLINE_FORM_LIST(state, payload) {
    state.whenOfflineFormLists.push(payload)
  },
  ADD_PRE_CHAT_FORM_LIST(state, payload) {
    state.preChatFormLists.push(payload)
  },
  REMOVE_WHEN_OFFLINE_FORM_LIST(state, payload) {
    state.whenOfflineFormLists.splice(payload, 1)
  },
  REMOVE_PRE_CHAT_FORM_LIST(state, payload) {
    state.preChatFormLists.splice(payload, 1)
  },
  UPDATE_WIDGET_PREVIEW_BODY_TYPE(state, payload) {
    state.widgetDesktopPreviewBodyType = payload
  },
  EDIT_WHEN_OFFLINE_FORM_LIST(state, payload) {
    state.whenOfflineFormLists.splice(payload.index, 1, payload.value)
  },
  REMOVE_DROPDOWN_ITEM_WHENOFFLINE(state, payload) {
    state.whenOfflineFormLists[payload.index].dropdownItem.splice(
      payload.itemIndex,
      1
    )
  },
  ADD_DROPDOWN_ITEM_WHENOFFLINE(state, payload) {
    state.whenOfflineFormLists[payload].dropdownItem.push({ value: '' })
  },
  UPDATE_DROPDOWN_ITEM_VALUE(state, payload) {
    state.whenOfflineFormLists[0].dropdownItem[payload.index] = payload.value
    // state.whenOfflineFormLists[payload.index][0].dropdownItem = payload.value
  },
  UPDATE_VALIDATE_WIDGET_CONTENT(state, payload) {
    if (payload.type === 'WHEN-ONLINE') {
      state.validateWidgetContent.whenOnline = payload.status
    } else if (payload.type === 'WHEN-OFFLINE') {
      state.validateWidgetContent.whenOffline = payload.status
    } else if (payload.type === 'PRE-CHAT-FORM-TITLE') {
      state.validateWidgetContent.preChatForm.title = payload.status
    } else if (payload.type === 'PRE-CHAT-FORM-SUBTITLE') {
      state.validateWidgetContent.preChatForm.subtitle = payload.status
    } else if (payload.type === 'GREETING') {
      state.validateWidgetContent.greetingMessage = payload.status
    }
  },
  UPDATE_ERROR_MSG_WIDGET_CONTENT(state, payload) {
    state.widgetContentErrorMsg = payload
  },
  UPDATE_WIDGET_OPEN(state, payload) {
    state.openBox.widgetOpen = payload
  },
  UPDATE_GREETING_OPEN(state, payload) {
    state.openBox.greetingOpen = payload
  },
  SET_FILE_LOGO(state, payload) {
    if (payload) {
      state.widgetContent.widgetLogo.is_active = true
      state.widgetContent.widgetLogo.file = payload.files
      state.widgetContent.widgetLogo.urlFile = payload.url
    } else {
      state.widgetContent.widgetLogo.is_active = false
      state.widgetContent.widgetLogo.file = null
      state.widgetContent.widgetLogo.urlFile = ''
    }
  },
}
